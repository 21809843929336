<template>
    <v-card flat class="pr-4 pb-4 pl-4">
        <v-container fluid>
            <v-row class="mt-4">
                <v-col
                    v-for="(channel, index) in channels"
                    :key="`${channel.ss_id}-memberChannel`"
                    cols="auto"
                >
                    <v-card
                        min-width="300"
                        max-width="300"
                        elevation="4"
                    >
                        <v-row
                            align="center"
                            justify="center"
                            class="py-4"
                        >
                            <v-img
                                max-width="200"
                                height="100"
                                contain
                                :src="require(`../../assets/images/portale/${channel.ss_id}.png`)"
                                :lazy-src="require(`../../assets/images/portale/${channel.ss_id}.png`)"
                            />
                        </v-row>
                        <v-divider/>
                        <v-card-title class="title">
                            {{ channel.ss_kurzname }}
                        </v-card-title>
                        <v-card-text>
                            <div style="min-height: 85px;">
                                <v-row class="ma-0 pa-0">
                                    <v-col class="ma-0 pa-0 subtitle-1">
                                        {{ $tc('memberChannels.activatedProperties', channel.activated_properties) }}
                                    </v-col>
                                </v-row>
                                <v-row class="ma-0 pa-0">
                                    <v-col class="ma-0 pa-0 subtitle-1">
                                        {{ getEditableInfoText(channel) }}
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card-text>
                        <v-divider class="mx-4"/>
                        <v-card-actions
                            class="px-4"
                            style="height: 75px"
                        >
                            <t-t-custom
                                v-if="getDisables[channel.ss_id]"
                                v-bind:tt-message="getMessages[channel.ss_id]"
                                tt-icon="mdi-alert-circle-outline"
                                tt-icon-size="large"
                                @iconClick="clickOnTooltipIcon(channel.ss_id)"
                            />
                            <v-progress-circular
                                v-else-if="!getDisables[channel.ss_id] && getLoaders[channel.ss_id]"
                                indeterminate
                                :color="getColors.secondary1"
                            />
                            <v-switch
                                :color="getColors.secondary1"
                                v-else
                                :value="true"
                                :input-value="!!getMemberChannels[channelsIndex][index].ss_activatedformember"
                                @change="setChannel(index, channel, $event !== null)"
                            />
                            <v-spacer/>
                            <v-btn
                                right
                                :color="getColors.secondary1"
                                text
                                :disabled="!channel.ss_activatedformember"
                                @click="goToChannel(channel)"
                            >
                                {{ $t('common.words.more') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import TabTitle from '@/components/Allgemein/Tabtitel.vue'
import TTSuperadminChanges from '@/components/Tooltips/TTAenderungenNurSuperAdmin.vue'
import TTCustom from '@/components/Tooltips/TTFreierText.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'ChannelCards',
    props: {
        channelsIndex: Number,
    },
    components: {
        TabTitle,
        TTSuperadminChanges,
        TTCustom,
    },
    data() {
        return {
            isVariantA: (this.channelsIndex === 0),
            isVariantB: (this.channelsIndex === 1),
            isVariantFree: (this.channelsIndex === 2),
            channels: [],
            ttMessage: [],
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getMember',
            'getMemberChannels',
            'getUser',
            'getDisables',
            'getMessages',
            'getLoaders',
            'getTab',
        ]),
    },
    watch: {
        //Info: Reassign channels in the store as soon as the socket event reloads the channels
        '$store.state.memberChannels': {
            deep: true,
            handler(newChannelState) {
                if (this.isVariantB) {
                    this.channels = newChannelState[this.channelsIndex]

                    this.checkDependingsForChannel()
                    this.checkContactInfosForSpecificChannel()
                }
            },
        },
        '$store.state.tab': function () {
            if (this.getTab === 1) {
                this.channels = this.getMemberChannels[this.channelsIndex]

                if (this.isVariantB)
                    this.checkContactInfosForSpecificChannel()
            }
        },
    },
    methods: {
        clickOnTooltipIcon(ssid) {
            switch (ssid) {
                case 10:
                case 30:
                    this.$store.commit('SET_TAB', 0)
                    break
            }
        },
        goToChannel(channel) {

            this.$store.commit('SET_MEMBER_CHANNEL', channel)
            this.$router.push(`/member/${ this.getMember.id }/member-channel/${ channel.ss_id }`)
        },
        getEditableInfoText(channel) {
            let texts = []

            if (channel.ss_mseditable)
                texts.push(this.$i18n.t('memberChannels.editable'))

            if (channel.ss_msmultieditable)
                texts.push(this.$i18n.t('memberChannels.multiEditable'))

            if (channel.ss_multiaccount)
                texts.push(this.$i18n.t('memberChannels.multiAccount'))

            return texts.join(' | ')
        },
        async setChannel(index, channel, value) {

            await this.$store.dispatch('updateAndSetMemberChannel', {
                channelsIndex: this.channelsIndex,
                index: index,
                status: value,
                memberid: this.getMember.id,
                textid: channel.ss_textid,
                ssid: channel.ss_id,
            })

            this.channels = this.getMemberChannels[this.channelsIndex]

            this.$socket.emit('broadcastEmitter', {
                method: 'memberChannelUpdate',
                force: true,
                memberid: parseInt(this.$route.params.memberid),
                user: this.$store.getters.getUsernameTexts,
            })

            this.checkDependingsForChannel()
        },
        checkDependingsForChannel() {
            this.$store.commit('SET_DISABLES', { [31]: false })
            this.$store.commit('SET_MESSAGES', { [31]: `` })

            if (!this.getMemberChannels[2][3].ss_activatedformember) {
                this.$store.commit('SET_DISABLES', { [31]: true })
                this.$store.commit('SET_MESSAGES', { [31]: `To enable Google, you must first enable and configure FBE!` })
            }

            if (this.getMemberChannels[2][3].ss_activatedformember && !this.$store.getters.getClients.length) {
                this.$store.commit('SET_DISABLES', { [31]: true })
                this.$store.commit('SET_MESSAGES', { [31]: `To enable Google, there must be clients present!` })
            }
        },
        checkContactInfosForSpecificChannel() {
            let alertMessage = ''
            let disabled = true

            //Info: When activating Booking.com, check if mail address and phone number are stored for the member
            const mail = this.getMember.booking_mailcontact
            const phone = this.getMember.booking_phonecontact

            alertMessage += 'Missing'

            if (!mail && phone)
                alertMessage += ' mail'
            else if (mail && !phone)
                alertMessage += ' phone'
            else if (!mail && !phone)
                alertMessage += ' mail and phone'
            else
                disabled = false

            alertMessage += ' contact for member ' + this.getMember.id + ' - Click to edit'

            this.$store.commit('SET_DISABLES', { [10]: disabled })
            this.$store.commit('SET_MESSAGES', { [10]: alertMessage })

            this.$store.commit('SET_DISABLES', { [30]: disabled })
            this.$store.commit('SET_MESSAGES', { [30]: alertMessage })
        },
    },
    created() {
        this.channels = this.getMemberChannels[this.channelsIndex]

        this.checkDependingsForChannel()

        if (this.isVariantB)
            this.checkContactInfosForSpecificChannel()
    },
}
</script>
