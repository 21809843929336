<template>
    <v-card flat class="pr-4 pb-4 pl-4">
        <tab-title :title="$t('paymentPolicies.heading', {memberid: getMember.id})" :tooltip="true"/>
        <v-card-text class="pl-0" v-html="getPaymentPlanText()"/>
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
                <v-col cols="8">
                    <Form/>
                </v-col>
            </v-row>
            <SubmitAction :valid="valid" @submit="saveForm"/>
        </v-form>
    </v-card>
</template>

<script>
import TabTitle from '@/components/Allgemein/Tabtitel.vue'
import { mapGetters } from 'vuex'

import Form from '@/components/Formulare/Formular.vue'
import SubmitAction from '@/components/Formulare/Absenden.vue'
import TTSuperadminChanges from '@/components/Tooltips/TTAenderungenNurSuperAdmin.vue'
import membersService from '@/services/members.service'

export default {
    name: 'PaymentPolicyTab',
    components: {
        TabTitle,
        Form,
        SubmitAction,
        TTSuperadminChanges,
    },
    data() {
        return {
            valid: true,
        }
    },
    computed: {
        ...mapGetters([
            'getMember',
            'getForm',
            'getTab',
            'getPolicies',
            'getUser',
        ]),
        models: function () {
            return [
                {
                    label: this.$i18n.t('common.labels.daysBeforeArrival', { char: ' (w)' }),
                    element: 'text',
                    type: 'number',
                    source: 'zahlplan_tagevoranreise',
                    required: true,
                    rules: {
                        filedName: this.$i18n.t('common.labels.daysBeforeArrival', { char: '' }),
                        range: [0, 999],
                    },
                },
                {
                    label: this.$i18n.t('common.labels.percentagePrepayment', { char: ' (x)' }),
                    element: 'text',
                    type: 'number',
                    source: 'zahlplan_prozentanzahlung',
                    placeholder: '12 or 18,5',
                    required: true,
                    rules: {
                        filedName: this.$i18n.t('common.labels.percentagePrepayment', { char: '' }),
                        range: [0, 100],
                    },
                },
                {
                    label: this.$i18n.t('common.labels.prepaymentUntilDays', { char: ' (y)' }),
                    element: 'text',
                    type: 'number',
                    source: 'zahlplan_anzahlungbistagenachbuchung',
                    required: true,
                    rules: {
                        filedName: this.$i18n.t('common.labels.prepaymentUntilDays', { char: '' }),
                        range: [0, 90],
                    },
                },
                {
                    label: this.$i18n.t('common.labels.daysBeforeFinalPayment', { char: ' (z)' }),
                    element: 'text',
                    type: 'number',
                    source: 'zahlplan_tagevoranreisefuerrestzahlung',
                    required: true,
                    rules: {
                        filedName: this.$i18n.t('common.labels.daysBeforeFinalPayment', { char: '' }),
                        range: [0, 90],
                    },
                },
                {
                    label: this.$i18n.t('common.labels.rentalConditions'),
                    placeholder: 'Rental conditions are set by Fewo-Verwalter or the rest api',
                    element: 'text',
                    type: 'url',
                    source: 'mietbed_text',
                    readonly: true,
                },
            ]
        },
        policies: {
            get() {
                return this.getForm
            },
            set(newVal) {
                this.$store.dispatch('setForms', newVal)
            },
        },
    },
    watch: {
        //Info: Reassign loader, models and form in the store as soon as the tab in member is changed
        '$store.state.tab': function () {
            if (this.getTab === 2) {
                this.$store.dispatch('setModels', this.models)
                this.policies = this.getPolicies
            }
        },
        '$store.state.locale': function () {
            this.$store.dispatch('setModels', this.models)
        },
    },
    methods: {
        getPaymentPlanText() {
            const tva = this.policies.zahlplan_tagevoranreise
            const pa = this.policies.zahlplan_prozentanzahlung
            const abtb = this.policies.zahlplan_anzahlungbistagenachbuchung
            const tvafr = this.policies.zahlplan_tagevoranreisefuerrestzahlung

            let w = 'W'
            let x = 'X'
            let y = 'Y'
            let z = 'X'

            if (tva !== '' && tva !== null) {
                if (tva < 0)
                    w = '🖕'
                else
                    w = tva
            }

            if (pa !== '' && pa !== null) {
                if (pa < 0)
                    x = '💩'
                else
                    x = pa
            }

            if (abtb !== '' && abtb !== null) {
                if (abtb < 0)
                    y = '🤮'
                else
                    y = abtb
            }

            if (tvafr !== '' && tvafr !== null) {
                if (tvafr < 0)
                    z = '☠️'
                else
                    z = tvafr
            }

            return this.$i18n.t('paymentPolicies.paymentPlanText', { w: w, x: x, y: y, z: z })
        },
        async saveForm() {
            await this.$store.dispatch('setLoaders', { submit: true })
            const response = await membersService.setMemberPolicies({ data: this.getForm, memberid: this.getMember.id })

            if (response) {
                this.policies = response[0]
                await this.$store.dispatch('setLoaders', { submit: false })
                await this.$store.dispatch('successfulBanner', `Policies for member ${ this.getMember.id } successfully set!`)

                this.$socket.emit('broadcastEmitter', {
                    method: 'memberPoliciesUpdate',
                    force: true,
                    memberid: parseInt(this.$route.params.memberid),
                    user: this.$store.getters.getUsernameTexts,
                })
            }
        },
    },
    created() {
        this.$store.dispatch('setModels', this.models)
        this.policies = this.getPolicies
    },
}
</script>
