var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pr-4 pb-4 pl-4",attrs:{"flat":""}},[_c('tab-title',{attrs:{"title":_vm.$t('member.heading', {memberid: _vm.member.id}),"tooltip":true}}),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('Form'),_c('Dialog',{attrs:{"data":{
                    title: _vm.dialogTitle,
                    text: _vm.dialogText,
                    replyAction: {
                        state: true,
                        btnText: 'Check channels'
                    }
                }},on:{"replyAction":_vm.gotToMemberChannelsAndResetDialog,"cancelAction":_vm.resetForm}})],1)],1),_c('SubmitAction',{attrs:{"valid":_vm.valid},on:{"submit":_vm.saveForm}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }