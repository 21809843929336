<template>
    <v-card flat class="pr-4 pb-4 pl-4">
        <tab-title :title="$t('member.heading', {memberid: member.id})" :tooltip="true"/>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
            <v-row>
                <v-col cols="8">
                    <Form/>
                    <Dialog
                      :data="{
                        title: dialogTitle,
                        text: dialogText,
                        replyAction: {
                            state: true,
                            btnText: 'Check channels'
                        }
                    }"
                      @replyAction="gotToMemberChannelsAndResetDialog"
                      @cancelAction="resetForm"
                    />
                </v-col>
            </v-row>
            <SubmitAction
              :valid="valid"
              @submit="saveForm"
            />
        </v-form>
    </v-card>
</template>

<script>
import Dialog from '@/components/Allgemein/Dialog'
import TabTitle from '@/components/Allgemein/Tabtitel.vue'
import Form from '@/components/Formulare/Formular.vue'
import SubmitAction from '@/components/Formulare/Absenden.vue'
import TTSuperadminChanges from '@/components/Tooltips/TTAenderungenNurSuperAdmin.vue'
import membersService from '@/services/members.service'
import { mapGetters } from 'vuex'

export default {
    name: 'BasicDataTabMember',
    components: {
        Dialog,
        TabTitle,
        Form,
        SubmitAction,
        TTSuperadminChanges,
    },
    data() {
        return {
            dialogTitle: 'Cannot update member',
            dialogText: '',
            valid: false,
        }
    },
    computed: {
        ...mapGetters([
            'getMember',
            'getColors',
            'getForm',
            'getTab',
            'getUser',
            'getDialog',
            'getLoaders',
        ]),
        models: function () {
            return [
                {
                    label: this.$i18n.t('common.labels.lastname'),
                    element: 'text',
                    type: 'text',
                    source: 'name',
                    readonly: true,
                },
                {
                    label: this.$i18n.t('common.labels.firstname'),
                    element: 'text',
                    type: 'text',
                    source: 'firstname',
                    readonly: true,
                },
                {
                    label: this.$i18n.t('common.labels.company'),
                    element: 'text',
                    type: 'text',
                    source: 'company',
                    readonly: true,
                },
                {
                    label: this.$i18n.t('common.labels.username'),
                    element: 'text',
                    type: 'text',
                    source: 'username',
                    readonly: true,
                },
                {
                    label: this.$i18n.t('common.labels.bookingMailContact'),
                    element: 'text',
                    type: 'text',
                    source: 'booking_mailcontact',
                    rules: {
                        filedName: 'Email',
                        email: true,
                        min: 0,
                        max: 50,
                    },
                },
                {
                    label: this.$i18n.t('common.labels.bookingPhoneContact'),
                    placeholder: 'e.g. +494261818340',
                    element: 'text',
                    type: 'text',
                    source: 'booking_phonecontact',
                    rules: {
                        filedName: 'Phone',
                        phone: true,
                        min: 0,
                        max: 50,
                    },
                },
                {
                    label: (this.$store.getters.getMember.password_trigger ? this.$i18n.t('common.labels.updatePassword') : this.$i18n.t('common.labels.setFirstPassword')),
                    element: 'text',
                    type: 'text',
                    source: 'password',
                    rules: {
                        filedName: 'Password',
                        min: 6,
                        max: 50,
                    },
                    permission: () => {
                        return !!this.$store.getters.getUser.is_admin
                    },
                },
                {
                    label: this.$i18n.t('common.labels.enableLogin'),
                    element: 'switch',
                    source: 'is_admin',
                    disabled: () => {
                        return !!this.$store.getters.getUser.feondi_staff
                    },
                    permission: () => {
                        return !!this.$store.getters.getUser.is_admin && this.$store.getters.getUser.id !== this.$store.getters.getActingMemberid
                    },
                },
                {
                    label: this.$i18n.t('common.labels.enableFewomatic'),
                    element: 'switch',
                    source: 'member_hatfewomatic',
                    disabled: () => {
                        return !!this.$store.getters.getUser.member_hatfewomatic
                    },
                    permission: () => {
                        return true
                    },
                },
                {
                    label: this.$i18n.t('common.labels.feondiStaff'),
                    element: 'switch',
                    source: 'feondi_staff',
                },
                {
                    label: this.$i18n.t('common.labels.superAdmin'),
                    element: 'switch',
                    source: 'is_superadmin',
                    permission: () => {
                        return !!this.$store.getters.getUser.is_superadmin && this.$store.getters.getUser.id !== this.$store.getters.getActingMemberid
                    },
                },
            ]
        },
        member: {
            get() {
                return this.getForm
            },
            set(newVal) {
                this.$store.dispatch('setForms', newVal)
            },
        },
    },
    watch: {
        //Info: Reassign loader, models and form in the store as soon as the tab in member is changed
        '$store.state.tab': function () {
            if (this.getTab === 0) {
                this.member = this.getMember
                this.$store.dispatch('setModels', this.models)

            }
        },
        '$store.state.form': {
            deep: true,
            immediate: true,
            handler() {
                logger.log(`this is valid: ${ this.valid }`)
                // this.valid = true
            },
        },
        '$store.state.locale': function () {
            this.$store.dispatch('setModels', this.models)
        },
    },
    methods: {
        gotToMemberChannelsAndResetDialog() {
            this.$store.dispatch('restoreForm')
            this.$store.commit('RESET_PROP', 'dialog')
            this.$store.commit('SET_TAB', 1)
        },
        resetForm() {
            this.$store.dispatch('restoreForm')
        },
        async saveForm() {
            const channels = this.$store.getters.getMemberChannels
            const memberChannels = [...channels[0], ...channels[1], ...channels[2]]

            const contactDataNeededChannelNames = []

            memberChannels.forEach(channel => {
                const ssid = channel.ss_id

                if (ssid === 10 || ssid === 30) {
                    if (channel.ss_activatedformember && (!this.getForm.booking_mailcontact || !this.getForm.booking_phonecontact))
                        contactDataNeededChannelNames.push(channel.ss_kurzname)
                }
            })

            if (contactDataNeededChannelNames.length) {
                this.dialogText = `You can't update the member while it still has channels <b>(${ contactDataNeededChannelNames.join(', ') })</b> enabled that require contact information.`
                this.$store.commit('SET_DIALOG', { state: true, data: '' })
                this.valid = false
            }

            if (this.valid) {
                await this.$store.dispatch('setLoaders', { submit: true })
                const response = await membersService.setMember(this.getForm)

                if (response) {
                    this.member = response
                    await this.$store.dispatch('setLoaders', { submit: false })
                    await this.$store.dispatch('successfulBanner', `Member ${ this.member.id } successfully updated!`)

                    this.$socket.emit('broadcastEmitter', {
                        method: 'memberUpdate',
                        force: true,
                        user: this.$store.getters.getUsernameTexts,
                    })

                    await this.$store.dispatch('getAndSetMemberChannels', { memberid: this.member.id, force: true })
                }
            }
        },
    },
    created() {
        this.member = this.getMember
        this.$store.dispatch('setModels', this.models)
    },
}
</script>
