<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-icon
                style="cursor: pointer"
                right
                :x-small="ttIconSize === 'x-small'"
                :small="ttIconSize === 'small'"
                :large="ttIconSize === 'large'"
                :x-large="ttIconSize === 'x-large'"
                :color="(ttColor ? ttColor : $store.getters.getColors.error)"
                v-bind="attrs"
                v-on="on"
                @click="$emit('iconClick')"
            >
                {{ ttIcon }}
            </v-icon>
        </template>
        <span>
            {{ ttMessage }}
        </span>
    </v-tooltip>
</template>

<script>
export default {
    name: 'TTCustom',
    props: {
        ttIcon: String,
        ttColor: String,
        ttIconSize: String,
        ttMessage: String,
    },
}
</script>
