<template>
    <div class="w-100 pl-4 pb-2 mb-4">
        <v-card outlined>
            <member-heading-details
                :title-text="`MEMBER_FIRSTNAME MEMBER_NAME (MEMBER_ID)`"
                :subtitle-text="$tc('common.memberHeadingDetails.member')"
            />
            <v-tabs
                v-model="tab"
                :background-color="getColors.primary"
                dark
                color="#ffffff"
                center-active
            >
                <v-tabs-slider :color="getColors.secondary1"/>
                <v-tab
                    v-for="(tabItem, index) in tabItems"
                    :key="`tabItem-${index}`"
                    @change="tab = index"
                >
                    <v-icon class="mr-1" small>
                        {{ tabItem.icon }}
                    </v-icon>
                    {{ tabItem.title }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item v-for="(tabItem, index) in tabItems" :key="`tabItem-${index}`">
                    <component v-bind:is="tabItem.component"/>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import MemberHeadingDetails from '@/components/Allgemein/KundentitelMitDetails.vue'
import BasicDataTabMember from '@/components/Kunde/Kundenstammdaten.vue'
import ChannelsTab from '@/components/Kunde/Schnittstellenvarianten.vue'
import PaymentPolicyTab from '@/components/Kunde/Zahlungsbedingungen.vue'

export default {
    name: 'Profile',
    components: {
        MemberHeadingDetails,
        BasicDataTabMember,
        ChannelsTab,
        PaymentPolicyTab,
    },
    metaInfo() {
        return {
            title: `${ this.getMember.firstname } ${ this.getMember.name } (${ this.getMember.id })`,
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getMembers',
            'getActingMemberid',
            'getMember',
            'getTab',
        ]),
        tab: {
            get() {
                return this.getTab
            },
            set(newValue) {
                this.$store.commit('SET_TAB', newValue)
            },
        },
        tabItems: function () {
            return [
                {
                    title: this.$i18n.t('common.tabs.basicData'),
                    icon: 'mdi-account-edit-outline',
                    component: 'BasicDataTabMember',
                },
                {
                    title: this.$i18n.t('common.tabs.channels'),
                    icon: 'mdi-share-variant-outline',
                    component: 'ChannelsTab',
                },
                {
                    title: this.$i18n.t('common.tabs.paymentPolicies'),
                    icon: 'mdi-cash',
                    component: 'PaymentPolicyTab',
                },
            ]
        },
    },
    methods: {},
    async created() {
        await Promise.all([
            await this.$store.dispatch('startActingAsMember', this.getActingMemberid),
            await this.$store.dispatch('getAndSetMemberChannels', { memberid: this.getActingMemberid }),
            await this.$store.dispatch('getAndSetPolicies', { memberid: this.getActingMemberid }),
            await this.$store.dispatch('getAndSetClients', { memberid: this.getActingMemberid }),
        ])
    },
    beforeRouteEnter(to, from, next) {
        next(async vm => {
            await vm.$store.dispatch('resetProps', ['interfaces', 'interface'])
        })
    },
    beforeRouteLeave(to, from, next) {
        this.$store.dispatch('resetProps', ['tab', 'form', 'models', 'disables', 'messages', 'clients'])
        next()
    },
}
</script>