<template>
    <v-card flat class="pr-4 pb-4 pl-4">
        <tab-title :title="$t('memberChannels.heading')"/>
        <v-expansion-panels
            v-if="getMemberChannels.length"
            flat
            multiple
            v-model="panel"
        >
            <v-expansion-panel
                v-for="(channelVariant, index) in getMemberChannels"
                :key="`${index}-memberChannel`"
            >
                <v-expansion-panel-header>
                    {{ $t('memberChannels.variantHeader', {
                  variant: getVariantType(channelVariant[0]),
                  memberid: getMember.id
                }) }}
                    <template v-slot:actions>
                        <v-icon :color="getColors.primary">
                            {{ panel.includes(index) ? 'mdi-unfold-less-horizontal' : 'mdi-unfold-more-horizontal' }}
                        </v-icon>
                    </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <channel-cards v-bind:channels-index="index"/>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <div v-else>
            <skeleton/>
        </div>
    </v-card>
</template>

<script>
import Skeleton from '@/components/Allgemein/Skeleton'
import TabTitle from '@/components/Allgemein/Tabtitel.vue'
import { mapGetters } from 'vuex'
import ChannelCards from '@/components/Kunde/Schnittstellenaktivierung.vue'

export default {
    name: 'ChannelsTab',
    components: {
        Skeleton,
        TabTitle,
        ChannelCards,
    },
    data() {
        return {
            panel: [0, 1, 2],
        }
    },
    computed: {
        ...mapGetters([
            'getMember',
            'getMemberChannels',
            'getColors',
        ]),
    },
    methods: {
        getVariantType(channel) {
            let type = ''

            if (!channel.ss_isterweitert && !channel.ss_istkostenlos)
                type = 'A'
            else if (channel.ss_istkostenlos)
                type = 'Free'
            else
                type = 'B'

            return type
        },
    },
}
</script>
